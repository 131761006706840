<template>
  <div class="p_func">
    <div class="flex-row">
      <div class="p_func_item">
        <div class="flex-row">
          <label>Mostrar</label>
          <select
            v-model="showing"
            class="form-control"
            style="min-width: 50px"
            @change="changeShowing"
          >
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <label>Items</label>
        </div>
      </div>
      <div class="p_func_item">
        <input
          v-if="searchable"
          type="text"
          :placeholder="this.search_placeholder != null ? this.search_placeholder : 'Buscar. . .'"
          class="form-control"
          v-model="search"
        />
      </div>
    </div>
    <div class="flex-row">
      <div class="p_func_item">
        <div>
          <ul class="flex-row">
            <li
              class="page-item-desktop"
              :class="current_page - 1 === 0 ? 'disabled' : ''"
              @click.prevent="changePage(1)"
            >
              <a class="page-link" href="#">Primera</a>
            </li>
            <li
              v-for="page in pages_list"
              :key="page.number"
              class="page-item-desktop"
              :class="current_page === page.number ? 'active' : ''"
              @click.prevent="changePage(page.number)"
            >
              <a class="page-link" href="#">{{ page.number }}</a>
            </li>
            <li
              class="page-item-desktop"
              :class="
                !lastPage || current_page + 1 > lastPage ? 'disabled' : ''
              "
              @click.prevent="changePage(lastPage)"
            >
              <a class="page-link" href="#">Última</a>
            </li>

            <li
              class="page-item-mobile"
              :class="current_page - 1 === 0 ? 'disabled' : ''"
              @click.prevent="changePage(current_page - 1)"
            >
              <a class="page-link" href="#">Anterior</a>
            </li>
            <li
              class="page-item-mobile"
              :class="current_page + 1 > lastPage ? 'disabled' : ''"
              @click.prevent="changePage(current_page + 1)"
            >
              <a class="page-link" href="#">Siguiente</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * # INPUT
 * - lastPage | required
 *
 * # OUTPUT
 * - changing_page
 * - searching
 * - changing_showing
 */
export default {
  props: {
    search_placeholder: {
      type: [String],
      default: "Buscar. . . "
    },
    search_min_length: {
      type: Number,
      default: null
    },
    lastPage: {
      type: [Number, String],
      default: 1,
    },
    actualPage: {
      type: [Number, String],
      default: 1,
    },
    perPage: {
      type: [Number, String],
      default: 10,
    },
    searchable: {
      type: [Boolean, Number],
      default: true,
    },
  },
  data() {
    return {
      showing: this.perPage || 10,
      current_page: this.actualPage || 1,
      pages_list: [],
      search: null,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    lastPage(oldVal, newVal) {
      this.doPagesList();
    },
    actualPage(val) {
      this.current_page = val;
    },
    search: {
      handler(value) {
      this.seendSearch(value)
    },}
  },
  mounted() {
    this.doPagesList();
  },
  methods: {
    doPagesList() {
      // Reseteo la lista
      this.pages_list = [];

      // Agrego los items antes del actual
      if (this.current_page > 3) {
        for (let i = this.current_page; i >= this.current_page - 2; i -= 1) {
          this.pages_list.unshift({
            number: i,
          });
        }
      } else {
        for (let i = 1; i <= this.current_page; i += 1) {
          this.pages_list.push({
            number: i,
          });
        }
      }

      // Agrego los items despues del actual
      let rest = this.lastPage - this.current_page;

      if (rest !== 0) {
        // reasigno a la cantidad maxima de paginas por delante
        rest = rest > 2 ? 2 : rest;

        for (
          let i = this.current_page + 1;
          i <= this.current_page + rest;
          i += 1
        ) {
          this.pages_list.push({
            number: i,
          });
        }
      }
    },

    changePage(number) {
      if (number > 0 || number <= this.lastPage()) {
        this.current_page = number;
        this.doPagesList();
        this.$emit("changing_page", number);
      }
    },

    changeShowing() {
      this.$emit("changing_showing", this.showing);
    },

    seendSearch(value){
      if(!this.search_min_length)
        this.$emit("searching", value);

      if(value.length >= this.search_min_length)
        this.$emit("searching", value);
    },

    // search(evt) {
    //   console.log(evt.target.value)
    //   this.$emit("searching", evt.target.value);
    // },
  },
};
</script>

<style scoped lang="scss">
.flex-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3px 0;
}
.p_func {
  margin: 30px 0;

  &_item {
    flex-grow: 1;
    text-align: center;

    input {
      max-width: 300px;
      display: block;
      margin-left: auto;
    }

    > .flex-row {
      align-items: flex-end;

      select {
        flex: 1;
        margin: 0 15px;
        max-width: 200px;
      }

      label:last-child {
        margin-right: auto;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    @media (max-width: 767px) {
      flex-grow: 0;
      width: 100%;

      input {
        margin: auto;
      }

      > .flex-row label:last-child {
        margin: unset;
      }
    }
  }
}
.page-item {
  &-mobile {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
  &-desktop {
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }
}

.page-link {
  min-width: 2.6em;
  margin-right: 0.5em;
  text-align: center;
  border-radius: 4em;
  color: var(--color-letramipoa);
  text-decoration: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  margin-left: 0;
  border-radius: 4rem;
}

.form-inline label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
}

.p_func .disabled {
  pointer-events: none;
  opacity: 0.5;
}
.active {
  .page-link {
    color: var(--color-primary);
    font-weight: bold;
  }
}
</style>
