import axios from "axios";
import {token_expired} from '@/acl/methods'

const baseUrl = process.env.VUE_APP_BACKEND_URL
export default {
  state: () => ({
    datos_personales: {},
     listaPersonas: [],
  }),
  mutations: {
    SET_DATOSPERSONALES(state, value) {
      state.datos_personales = value;
    },
    SET_LISTA_PERSONAS(state, value) {
      state.listaPersonas = value;
    },
  },
  actions: {

    getListaPersonas({ commit, getters }, params) {
      if (token_expired(getters.user)){
      return new Promise((resolve) => {
        axios
          .get(`${baseUrl}mi-poa/personas/paginado`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params,
            })
          .then((res) => {
            commit("SET_LISTA_PERSONAS", res.data.data);
            resolve();
          })
          .catch(this.$errorHandler);
      });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
        this.dispatch("logout");
      }
    },

    geteDatosPersonales({ getters, commit},) {
       if (token_expired(getters.user)){
        return new Promise((resolve) => {
            axios
              .get(`${baseUrl}mi-poa/getdatospersonales/` , {
                headers: {
                  Authorization: `Bearer ${getters.token}`,
                },
              })
              .then((res) => {
                commit("SET_DATOSPERSONALES", res.data);
                resolve();
              })
              .catch(this.$errorHandler);
          });
        }else{
          this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
          this.dispatch("logout");
        }
    },

    updateDatosPersonales({ getters, dispatch, commit }, form) {
      if (token_expired(getters.user)){
        commit("SHOW_LOADER");
        return new Promise((resolve) => {
          axios
            .put(`${baseUrl}mi-poa/changedatospersonales/`, form, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then(({ data }) => {
              dispatch("geteDatosPersonales");
              this.$toast.success(data.data);
              resolve(data);
            })
            .catch(this.$errorHandler)
            .finally(() => {
              commit("HIDE_LOADER");
            });
        });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
          this.dispatch("logout");
        }
    },
  },
  getters: {
    datos_personales: (state) => state.datos_personales,
    listaPersonas: (state) => state.listaPersonas,
  },
};
