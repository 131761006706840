import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    component: () => import("@/views/Auth/Login.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/Auth/Register.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/Dashboard/DashboardLayout.vue"),    
    children: [
      {
        path: "dashboard",
        component: () => import("@/views/Dashboard/Dashboard.vue"),
      },
      {
        path: "opciones",
        component: () => import("@/views/Dashboard/MyUser/Options.vue"),
      },
      {
        path: "nuevaInscripcion",
        component: () => import("@/views/Dashboard/Torneos/inscripcion.vue"),
      },
      {
        path: "inscripcion",
        component: () => import("@/views/Dashboard/Torneos/inscripcion.vue"),
      },
      {
        path: "inbox",
        component: () => import("@/views/Dashboard/MyUser/inbox.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


export default router;
