const baseUrl = process.env.VUE_APP_BACKEND_URL;
import axios from "axios";
import {token_expired} from '@/acl/methods'

export default {
  state: {
    canesM: {},
    canesF: {},
    padresDogs: {},
    grafico: {},
  },
  mutations: {
    SET_GRAFICO(state, value) {
      state.grafico = value;
    },
    SET_MACHOS(state, value) {
      state.canesM = value;
    },
    SET_HEMBRAS(state, value) {
      state.canesF = value;
    },
     SET_PADRES(state, value) {
      state.padresDogs = value;
    },
  },
  actions: {
    getGrafico({ commit, getters }, id) {
      if (token_expired(getters.user)){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}mi-poa/getgrafico/${id}` , {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then((res) => {
              commit("SET_GRAFICO", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
          this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
          this.dispatch("logout");
        }
    },
    getMachos({ commit, getters }, {id,params}) {
      if (token_expired(getters.user)){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}mi-poa/canes/${id}` , {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params
            })
            .then((res) => {
              commit("SET_MACHOS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
          this.dispatch("logout");
        }
    },
    getHembras({ commit, getters }, {id,params}) {
      if (token_expired(getters.user)){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}mi-poa/canes/${id}`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
              params
            })
            .then((res) => {
              commit("SET_HEMBRAS", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
          this.dispatch("logout");
        }
    },
    getPadres({ commit, getters }, id) {
      if (token_expired(getters.user)){
        return new Promise((resolve) => {
          axios
            .get(`${baseUrl}mi-poa/can/${id}/padres`, {
              headers: {
                Authorization: `Bearer ${getters.token}`,
              },
            })
            .then((res) => {
              commit("SET_PADRES", res.data);
              resolve();
            })
            .catch(this.$errorHandler);
        });
      }else{
        this.$toast.error("Por Seguridad, Vuelva a Iniciar Sesion")
          this.dispatch("logout");
        }
    },
  },
  getters: {
    canesM: (state) => state.canesM,
    canesF: (state) => state.canesF,
    grafico: (state) => state.grafico,
    padresDogs: (state) => state.padresDogs,
  },
};
