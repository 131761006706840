import { createStore } from "vuex";
import loader from "./_loader";
import auth from "./_auth";
import canes from "./_canes";
import personas from "./_personas";
import torneos from "./torneos";



export default createStore({
  modules: {
    auth,
    loader,
    canes,
    torneos,
    personas,
  },
});
